/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const liveConfigRouter = {
  path: '/live-config',
  component: Layout,
  redirect: '/live-config/live-switch',
  name: 'LiveConfig',
  meta: {
    title: 'liveConfig',
    icon: 'user'
  },
  children: [
    // 直播间功能入口
    {
      path: 'live-switch',
      component: () => import('@/views/live-config/live-switch/index'),
      name: 'LiveSwitch',
      meta: { title: 'liveSwitch', noCache: false }
    },
    // 用户任务管理
    {
      path: 'user-task',
      component: () => import('@/views/live-config/user-task/index'),
      name: 'UserTask',
      meta: { title: 'userTask', noCache: false }
    },
    // 装扮管理
    {
      path: 'dress-config',
      component: () => import('@/views/live-config/dress-config/index'),
      name: 'DressConfig',
      meta: { title: 'dressConfig', noCache: false }
    },
    // 主播任务配置
    {
      path: 'anchor-task',
      component: () => import('@/views/live-config/anchor-task/index'),
      name: 'AnchorTask',
      meta: { title: 'anchorTask', noCache: false }
    },
    // 跑道配置
    {
      path: 'runway-config',
      component: () => import('@/views/live-config/runway-config/index'),
      name: 'RunwayConfig',
      meta: { title: 'runwayConfig', noCache: false }
    },
    // 歌曲上传
    {
      path: 'music-upload',
      component: () => import('@/views/live-config/music-upload/index'),
      name: 'MusicUpload',
      meta: { title: 'musicUpload', noCache: false }
    },
    // 热搜推荐池
    {
      path: 'hot-search-recommendation',
      component: () => import('@/views/live-config/hot-search-recommendation/index'),
      name: 'HotSearchRecommendation',
      meta: { title: 'hotSearchRecommendation', noCache: false }
    },
    // 氛围图配置
    {
      path: 'atmosphere-config',
      component: () => import('@/views/live-config/atmosphere-config/index'),
      name: 'AtmosphereConfig',
      meta: { title: 'atmosphereConfig', noCache: false }
    },
    //敏感词库
    {
      path: 'sensitive',
      component: () => import('@/views/live-config/sensitive/index'),
      name: 'Sensitive',
      meta: { title: 'sensitive', noCache: false }
    },
    //直播功能配置
    {
      path: 'liveFunctionConfig',
      component: () => import('@/views/live-config/live-function-config/index'),
      name: 'LiveFunctionConfig',
      meta: { title: 'liveFunctionConfig', noCache: false }
    },
    //直播功能配置-直播间软更新弹窗
    {
      path: 'liveRoomWindow',
      component: () => import('@/views/live-config/live-function-config/window'),
      name: 'LiveRoomWindow',
      meta: { title: 'liveRoomWindow', noCache: false }
    },
    //猫饼兑换
    {
      path: 'biscuits-exchange',
      component: () => import('@/views/live-config/biscuits-exchange/index'),
      name: 'BiscuitsExchange',
      meta: { title: 'biscuitsExchange', noCache: false }
    },
    //推荐位配置
    {
      path: 'position-config',
      component: () => import('@/views/live-config/position-config/index'),
      name: 'PositionConfig',
      meta: { title: 'PositionConfig', noCache: false }
    },
    //首页推荐位
    {
      path: 'home-recommend-range/:liveType',
      component: () => import('@/views/live-config/position-config/home/range/index'),
      name: 'HomeRecommendRange',
      meta: { title: 'HomeRecommendRange', noCache: false }
    },
    // 推荐位曝光数据
    {
      path: 'recommend-exposure',
      component: () => import('@/views/live-config/position-config/hotSearch/exposure/index'),
      name: 'RecommendExposure',
      meta: { title: 'RecommendExposure', noCache: false }
    },
    //地区筛选配置
    {
      path: 'area-filter',
      component: () => import('@/views/live-config/areaFilter/list'),
      name: 'AreaFilter',
      meta: { title: 'areaFilter', noCache: false }
    },
    //直播间音效
    {
      path: 'banner-config',
      component: () => import('@/views/live-config/banner-config/index'),
      name: 'BannerConfig',
      meta: { title: 'bannerConfig', noCache: false }
    },
    //直播间音效
    {
      path: 'live-sound',
      component: () => import('@/views/live-config/live-sound/index'),
      name: 'LiveSound',
      meta: { title: 'liveSound', noCache: false }
    },
    // 体育直播H5
    {
      path: 'sports-live',
      component: () => import('@/views/live-config/sports-live/index'),
      name: 'SportsLive',
      meta: { title: 'sportsLive', noCache: false }
    }
  ]
}

export default liveConfigRouter
