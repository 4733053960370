import request from '@/utils/request'

export function getAllRegion(params = {}) {
  return request({
    url: '/cms/backend/public/allRegion',
    method: 'get',
    params
  })
}

export function getAllByType(data = {}) {
  return request({
    url: '/cms/backend/tags/allByType',
    method: 'post',
    data,
    loading: true
  })
}

export function getAllByChildType(data = {}) {
  return request({
    url: '/cms/backend/tags/getChildTags',
    method: 'post',
    data
  })
}

export function getEnumAll(params = {}) {
  return request({
    url: '/cms/backend/enum/getAll',
    method: 'get',
    params
  })
}

export function getEnumList(params = {}) {
  return request({
    url: '/cms/backend/enum/list',
    method: 'get',
    params
  })
}

export function getRandom(params = {}) {
  return request({
    url: '/cms/backend/up/getRandom',
    method: 'get',
    params
  })
}

export function doEnableOrDisable(data = {}) {
  return request({
    url: '/cms/backend/episode/doEnableOrDisable',
    method: 'post',
    data
  })
}

export function saveOrUpdateEpisode(data = {}) {
  return request({
    url: '/cms/backend/episode/saveOrUpdate',
    method: 'post',
    data
  })
}

export function querySubtitlingList(data = {}) {
  return request({
    url: `/cms/backend/episode/querySubtitlingList`,
    method: 'post',
    data,
    loading: true
  })
}

export function saveSubtitlingList(data = {}) {
  return request({
    url: '/cms/backend/episode/saveSubtitlingList',
    method: 'post',
    data,
    loading: true
  })
}

export function batchDeleteSubtitling(data = {}) {
  return request({
    url: `/cms/backend/episode/batchDeleteSubtitling`,
    method: 'post',
    data
  })
}

//收费版本
export function batchTranslate(data = {}) {
  return request({
    url: '/third/google/batchTranslate',
    method: 'post',
    data,
    loading: true
  })
}

//免费版本
export function batchTranslateFree(data = {}) {
  return request({
    baseURL: process.env.VUE_APP_NODE_API,
    url: '/api/translate',
    method: 'post',
    data,
    timeout: 180000,
    withCredentials: false
  })
}

//修改密码
export function updatePwd(data = {}) {
  return request({
    url: '/auth/backend/account/updatePwd',
    method: 'post',
    data,
    loading: true
  })
}

//删除字幕
export function delSubtitling(query = {}) {
  return request({
    url: `/cms/backend/episode/delSubtitling?id=${query.id}`,
    method: 'delete',
    loading: true
  })
}

//获取最低清晰度m3u8资源下载链接
export function downloadInfo(id) {
  return request({
    url: `/media/backend/resource/downloadInfo?episodeId=${id}`,
    method: 'get',
    loading: true
  })
}

export function getAllCopyrightTag() {
  return request({
    url: `/cms/backend/tags/allCopyrightTag`,
    method: 'get',
    loading: true
  })
}

export function getAllLangListTag() {
  return request({
    url: `/cms/backend/movie/langList`,
    method: 'get'
  })
}

//公共导出
export function commonExport(data = {}) {
  return request({
    url: '/excel/common/export',
    method: 'post',
    data,
    loading: true
  })
}

export function tags_get(data = {}) {
  return request({
    url: '/cms/backend/tags/get',
    method: 'post',
    data,
    loading: true
  })
}

// ai字幕翻译任务 操作
export function episode_operateAiSubtitlingTask(data) {
  return request({
    url: '/media/backend/episode/operateAiSubtitlingTask',
    method: 'post',
    data,
    loading: true
  })
}

// ai字幕翻译任务 创建
export function episode_createAiSubtitlingTask(data) {
  return request({
    url: '/media/backend/episode/createAiSubtitlingTask',
    method: 'post',
    data,
    loading: true
  })
}

// ai字幕翻译任务 详情
export function episode_getAISubtitlingTaskContent(params, loading = true) {
  return request({
    url: `/media/backend/episode/getAISubtitlingTaskContent`,
    method: 'get',
    params,
    loading
  })
}

// 剧集/电影列表-批量更新
export function excel_updateMovieDrama(data) {
  return request({
    url: `/cms/backend/excel/updateMovieDrama`,
    method: 'post',
    data,
    loading: true
  })
}

// 查询AI翻译任务

export function queryAiTranslateTask(data) {
  return request({
    url: `/media/backend/episode/queryAiSubtitlingTask`,
    method: 'post',
    data,
    loading: true
  })
}
