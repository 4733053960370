import Layout from '@/layout'

const taskCenterRouter = {
  path: '/task-center',
  component: Layout,
  redirect: '/task-center/task-list',
  name: 'TaskCenter',
  meta: {
    title: 'taskCenter',
    icon: 'people'
  },
  children: [
    {
      path: 'task-list',
      component: () => import('@/views/task-center/task-list/index'),
      name: 'TaskList',
      meta: { title: 'taskList', noCache: false }
    },
    {
      path: 'store',
      component: () => import('@/views/task-center/store/index'),
      name: 'TaskStore',
      meta: { title: 'taskStore', noCache: false }
    },
    {
      path: 'integral-detail',
      component: () => import('@/views/task-center/integral-detail/index'),
      name: 'IntegralDetail',
      meta: { title: 'integralDetail', noCache: false }
    },
    {
      path: 'task-list/audit',
      component: () => import('@/views/task-center/task-list/audit/list.vue'),
      name: 'TaskAuditList',
      meta: { title: 'taskAuditList', noCache: false }
    },
    {
      path: 'withdraw',
      component: () => import('@/views/task-center/withdraw/index'),
      name: 'Withdraw',
      meta: { title: 'Withdraw', noCache: false }
    },
    {
      path: 'fission-invite',
      component: () => import('@/views/activity-manage/fission-invite/index'),
      name: 'FissionInvite',
      meta: { title: 'fissionInvite', noCache: false }
    },
    {
      path: 'api-list',
      component: () => import('@/views/task-center/api-list/list'),
      name: 'apiList',
      meta: { title: 'apiList', noCache: false }
    },
  ]
}

export default taskCenterRouter
