/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const appManageRouter = {
  path: '/app-manage',
  component: Layout,
  redirect: '/app-manage/app-list',
  name: 'AppManage',
  meta: {
    title: 'appManage',
    icon: 'component'
  },
  children: [
    {
      path: 'first-level',
      component: () => import('@/views/app-manage/filter-panel/first-level/index'),
      name: 'FirstLevelType',
      meta: { title: 'filterPanelConfig', noCache: true }
    },
    {
      path: 'second-level',
      component: () => import('@/views/app-manage/filter-panel/second-level/index'),
      name: 'SecondLevelType',
      meta: { title: 'filterSecondLevelConfig', noCache: true },
      hidden: true
    },
    {
      path: 'third-level',
      component: () => import('@/views/app-manage/filter-panel/third-level/index'),
      name: 'ThirdLevelType',
      meta: { title: 'thirdSecondLevelConfig', noCache: true },
      hidden: true
    },
    {
      path: 'client',
      component: () => import('@/views/app-manage/client'),
      name: 'applicationClient',
      meta: {
        title: 'versionManage',
        // children: [{ name: 'ClientManage', title: '版本管理' }, { name: 'Channel', title: '渠道管理' }, { name: 'Force', title: '强更管理' }, { name: 'Tip', title: '提示更新' }, { name: 'Check', title: '检查更新' }]
        children: [
          { name: 'ClientManage', title: '版本管理' },
          { name: 'Channel', title: '渠道管理' },
          { name: 'Force', title: '强更管理' },
          { name: 'Tip', title: '提示更新' },
          { name: 'Status', title: '状态管理' },
          { name: 'White', title: '域名白名单' }
        ]
      }
    },
    {
      path: 'client/ClientManage/ClientVersion',
      component: () => import('@/views/app-manage/client/ClientManage/ClientVersion.vue'),
      name: 'ClientClientManageClientVersion',
      hidden: true,
      meta: {
        noCache: true,
        activeMenu: '/application/client',
        title: '版本管理'
      }
    },
    {
      path: 'client/ClientManage/ClientChannel',
      component: () => import('@/views/app-manage/client/ClientManage/ClientChannel.vue'),
      name: 'ClientClientManageClientChannel',
      hidden: true,
      meta: {
        noCache: true,
        activeMenu: '/application/client',
        title: '版本渠道'
      }
    },
    {
      path: 'client/Channel/channelClientType',
      component: () => import('@/views/app-manage/client/Channel/channelClientType.vue'),
      name: 'ClientChannelchannelClientType',
      hidden: true,
      meta: {
        noCache: true,
        activeMenu: '/application/client',
        title: '渠道管理'
      }
    },
    // {
    //   path: 'article-list',
    //   component: () => import('@/views/app-manage/article/article-list-system.vue'),
    //   name: 'ArticleList',
    //   meta: {
    //     title: 'articleList',
    //     noCache: false
    //   }
    // },
    {
      path: 'article-list',
      component: () => import('@/views/app-manage/article/index.vue'),
      name: 'ArticleManage',
      meta: {
        title: 'articleManage',
        noCache: false
      }
    },
    {
      path: 'search-setting',
      component: () => import('@/views/app-manage/search/setting.vue'),
      name: 'SearchSetting',
      meta: {
        title: 'searchSetting',
        noCache: false
      }
    },
    {
      path: 'home-setting',
      component: () => import('@/views/app-manage/home/setting.vue'),
      name: 'HomeSetting',
      meta: {
        title: 'homeSetting',
        noCache: false
      }
    },
    {
      path: 'home-components',
      component: () => import('@/views/app-manage/home-components/list.vue'),
      name: 'HomeComponents',
      meta: {
        title: 'homeComponents',
        noCache: false
      }
    },
    {
      path: 'plate-setting',
      component: () => import('@/views/app-manage/home-components/plate'),
      name: 'PlateSetting',
      meta: {
        title: 'plateSetting',
        noCache: true
      },
      hidden: true
    },
    {
      path: 'home-page-list/:navigationId',
      component: () => import('@/views/app-manage/home/list.vue'),
      name: 'HomePageList',
      props: true,
      meta: {
        title: 'homePageList',
        noCache: false,
        custom: true
      },
      hidden: true
    },
    {
      path: 'bar-setting',
      component: () => import('@/views/app-manage/bar/index.vue'),
      name: 'BarSetting',
      meta: {
        title: 'barSetting',
        noCache: false
      }
    },
    {
      path: 'auth-setting',
      component: () => import('@/views/app-manage/auth/setting'),
      name: 'AuthSetting',
      meta: {
        title: 'authSetting',
        noCache: true
      }
    },
    {
      path: 'float-window',
      component: () => import('@/views/app-manage/float-window/list'),
      name: 'FloatWindow',
      meta: {
        title: 'floatWindow',
        noCache: false
      }
    },
    {
      path: 'cdn-setting',
      component: () => import('@/views/app-manage/cdn/setting'),
      name: 'CdnSetting',
      meta: {
        title: 'cdnSetting',
        noCache: false
      }
    },
    {
      path: 'emoji-list',
      component: () => import('@/views/app-manage/emoji/list'),
      name: 'EmojiList',
      meta: {
        title: 'emojiList',
        noCache: false
      }
    },
    {
      path: 'watch-together',
      component: () => import('@/views/app-manage/watch-together/list'),
      name: 'WatchTogether',
      meta: {
        title: 'watchTogether',
        noCache: false
      }
    },
    {
      path: 'home-large-window',
      component: () => import('@/views/app-manage/home-large-window/list'),
      name: 'HomeLargeWindow',
      meta: {
        title: 'homeLargeWindow',
        noCache: false
      }
    },
    {
      path: 'note-switchover',
      component: () => import('@/views/app-manage/note-switchover/index'),
      name: 'NoteSwitchover',
      meta: {
        title: 'noteSwitchover',
        noCache: false
      }
    },
    {
      path: 'device-manage',
      component: () => import('@/views/app-manage/device-manage/index'),
      name: 'DeviceManage',
      meta: {
        title: 'deviceManage',
        noCache: false
      }
    },
    {
      path: 'rank-manage',
      component: () => import('@/views/app-manage/rank/index'),
      name: 'RankManage',
      meta: {
        title: 'rankManage',
        noCache: false
      }
    },
    {
      path: 'rank-blacklist/:area',
      component: () => import('@/views/app-manage/rank/blacklist/list'),
      name: 'RankBlacklist',
      hidden: true,
      meta: {
        title: 'rankBlacklist',
        noCache: true
      }
    },
    {
      path: 'rank-list',
      component: () => import('@/views/app-manage/rank/list/list'),
      name: 'RankList',
      hidden: true,
      meta: {
        title: 'rankList',
        noCache: true
      }
    },
    // 短剧推荐池
    {
      path: 'short-recommendation',
      component: () => import('@/views/app-manage/short-recommendation/list'),
      name: 'ShortRecommendation',
      hidden: true,
      meta: {
        title: 'shortRecommendation',
        noCache: true
      }
    },
    {
      path: 'home-tv-setting',
      component: () => import('@/views/app-manage/home-tv/setting.vue'),
      name: 'HomeTvSetting',
      meta: {
        title: 'homeTvSetting',
        noCache: false
      }
    },
    {
      path: 'home-tv-page-list/:navigationId',
      component: () => import('@/views/app-manage/home-tv/list.vue'),
      name: 'HomeTvPageList',
      props: true,
      meta: {
        title: 'homeTvPageList',
        noCache: false,
        custom: true
      },
      hidden: true
    },
    // 通用弹窗配置
    {
      path: 'common-popup',
      component: () => import('@/views/app-manage/common-popup/list'),
      name: 'CommonPopup',
      hidden: true,
      meta: {
        title: 'commonPopup',
        noCache: true
      }
    },
    {
      path: 'home-pc-setting',
      component: () => import('@/views/app-manage/home-pc/setting.vue'),
      name: 'HomePcSetting',
      meta: {
        title: 'homePcSetting',
        noCache: false
      }
    },
    {
      path: 'home-pc-page-list/:navigationId',
      component: () => import('@/views/app-manage/home-pc/list.vue'),
      name: 'HomePcPageList',
      props: true,
      meta: {
        title: 'homePcPageList',
        noCache: false,
        custom: true
      },
      hidden: true
    },
    {
      path: 'detail-banner',
      component: () => import('@/views/app-manage/detail-banner/index'),
      name: 'DetailBanner',
      meta: {
        title: 'detailBanner',
        noCache: false
      }
    }
  ]
}

export default appManageRouter
